import React, { useEffect, useState } from "react";
import { BannerService } from "../../../services/BannerService";
import { ThreeCircles } from "react-loader-spinner";

import DEV from "../../../url";
import "./Carousel.css";

function Items() {
  const [items, setItems] = useState([]); // Initialisation vide pour les données
  const [isLoading, setIsLoading] = useState(true); // État de chargement
  const service = new BannerService();

  // Chargement des données avec effet
  useEffect(() => {
    service
      .getBanners()
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          setItems(res); // Stocker les données reçues
        }
        setIsLoading(false); // Désactiver le loader après chargement
      })
      .catch((err) => {
        console.error("Error fetching banners:", err);
        setIsLoading(false);
      });
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0); // Gérer l'index actuel

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Pour centrer verticalement
          }}
        >
          <ThreeCircles
            visible={true}
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="three-circles-loading"
          />
        </div>
      ) : (
        
        <div className="carousel-container">
          <div className="carousel">
            <button
              className="carousel-button prev"
              onClick={() =>
                setCurrentIndex(
                  (currentIndex - 1 + items.length) % items.length
                )
              }
            >
              &#10094;
            </button>

            <div className="carousel-items">
              {items.map((item, index) => (
                <div
                  key={item?.id || index}
                  className={`carousel-item ${
                    index === currentIndex ? "active" : ""
                  }`}
                >
                  <img
                    src={`${DEV}uploads/banners/${item?.img}`}
                    className="d-block w-100"

                    alt={item?.title}
                  />
                  <div className=" text-content space-mobile pb-5">
                    <h6
                      className="mx-3 mt-5 responsive-content"
                      style={{ color: "#03AC13", fontWeight: "bold" }}
                    >
                      {item?.subtitle}
                    </h6>
                    <h1 className="responsive-content mx-3 text-white title-banner-size-mobile">
                      {item?.title}
                    </h1>
                    <div className="row">
                      <div className="mx-4 px-2 responsive">
                        <p
                          className="show-text"
                          style={{
                            textAlign: "justify",
                            color: "#fff",
                            fontSize: "18px",
                          }}
                        >
                          {item?.text}
                        </p>
                      </div>
                    </div>
                    <div align="center">
                      <div className="row mt-3" align="center">
                        <div className="col-lg-4 col-sm-6">
                          <a
                            target={"_blank"}
                            href="https://www.maisonsdhotesentunisie.com/vr/maisons/zaghouan-dar_zaghouan/"
                            className="filled-btn-visite filled-button text-center text-banner-btn-size"
                            rel="noreferrer"
                          >
                            Faites une visite virtuelle
                          </a>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          <a
                            className="filled-btn-visite  text-center text-banner-btn-size"
                            href={`tel:+21623724000`}
                            style={{ fontSize: 16, width: '100%' }}
                          >
                            <i className={`pi pi-phone`}></i> &nbsp;+216 23 724
                            000
                          </a>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <a
                            target="_blank"
                            href="https://darzaghouan.kinko.info/iframe?key=H-sVS8XcnRsSKSYi5T5rbNRA4n"
                            className="filled-button" rel="noreferrer"
                          >
                            Réservez dès maintenant
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <button
              className="carousel-button next"
              onClick={() => setCurrentIndex((currentIndex + 1) % items.length)}
            >
              &#10095;
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Items;
