import React, { useState, useEffect } from "react";

import { WhoareweService } from "../../../../services/WhoareweService";
import DEV from "../../../../url";

export default function ContentAboutUs() {
  const service = new WhoareweService();
  const [items, setItems] = useState([null]);

  useEffect(() => {
    service.getContentSectionsNotToMainPage().then((res) => {
      setItems(res.data);
    });
  }, []);

  return (
    <div style={{ paddingTop: 100 }} id={"histoire"}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div className="col-md-12">
            <div className="more-info-content">
              {item?.img != null ? (
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="right-content">
                      <span style={{ textTransform: "none" }}>{item?.tag}</span>
                      <h2>{item?.sectionName}</h2>

                      <p className="text-justify">{item?.text}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mt-5">
                    <div align={"right"} className="left-image">
                      <img
                        style={{ height: 500 }}
                        src={`${DEV}uploads/whoarewe/${item?.img}`}
                        alt={item?.sectionName}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="justify-content-center align-items-center">
                      <p className="text-center" style={{fontSize:15, textAlign:"center", textTransform: "none" }}>{item?.tag}</p>
                      <h2 className="text-center">{item?.sectionName}</h2>

                      <p className="text-center">{item?.text}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ padding: "50px" }}></div>
        </React.Fragment>
      ))}
    </div>
  );
}
