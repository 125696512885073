import React, { useState, useEffect } from 'react';
import "../../../../assets/css/packs.css"
import { PackService } from '../../../../services/PackService';
import {ContactInformation} from "../../../../services/ContactInformation";
import IconPack from "../../../../assets/images/iconpack.png";
import PackHeading from "./PackHeading";
import DEV from '../../../../url';

function Packs() {

    const service = new PackService();

    const [items, setItems] = useState([]);
    const all = async () => {
        service.all().then((res) => {
            setItems(res);
        });
    }

    useEffect(() => {
        all();
    }, []);




    return (
        <React.Fragment>
            <PackHeading/>
            <div className="container mt-5 pt-5" id={"packs"}>
                <div className="row pt-5">
                    {
                        items.map((item, index) => (

                            <React.Fragment key={index}>
                                <div  className="col-lg-4 col-md-6 col-sm-12">
                                    <div  className="card-wrapper mt-4">
                                        <div className="card-img">
                                            <img  style={{height:400}} src={`${DEV}uploads/packs/${item?.img}`} alt=""/>
                                        </div>
                                        <div className="card-body">
                                            <div>
                                                <img width={240} src={IconPack} alt={item.title} />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="text-center mt-4">
                                            <div className="row">
                                               <div className="col-lg-6" >
                                                    <h6 style={{fontSize:17}} className=" text-secondary mb-2"> {item.title}</h6>
                                               </div>
                                               <div className="col-lg-6" >
                                                   {
                                                       item.price === 0 ?
                                                           <></> :
                                                           <React.Fragment>
                                                                <small className={"text-success"}><b>A partir de</b> </small> <p className={"text-success"}><b>{item.price}{" DT / Personne"}</b></p>

                                                           </React.Fragment>
                                                   }
                                               </div>
                                           </div>

                                        </div>
                                    <div align={"center"} className="product-rating mb-2">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 mt-2">
                                                        <a href={`/${item.id}|pack-details`} className="filled-btn-avis mt-2">

                                                                Voir détails

                                                        </a>
                                                    </div>

                                                </div>
                                            </div>

                                </div>

                            </React.Fragment>
                        ))
                    }
                </div>

            </div>


            <br />
        </React.Fragment>
    );
}

export default Packs;
