import React from "react";
import "./assets/css/style.css";
import "./assets/css/RoomDetails.css";
import "./assets/css/ProductItems.css";
import "./assets/css/roomsSection.css";
import "./assets/css/detailsbreak.css";
import "./assets/css/responsive1.css";

import "./assets/css/events.css";
import "./assets/css/fontawesome.css";
import 'primeicons/primeicons.css';







import {
    BrowserRouter as Router,
} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import MainRoutes from "./routes/MainRoutes";
import NavInfo from "./components/header/NavInfo";
import NavBar from "./components/header/NavBar";
import Footer from "./components/footer/Footer";
function App() {
    return (
        <Router>
            <NavInfo />
            <NavBar />
            <MainRoutes/>
            <Footer />
            <ScrollToTop smooth  style={{border:"1px solid #03ac13"}}/>
        </Router>
    );
}

export default App;
