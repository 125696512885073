import React, { useEffect, useState } from "react";

import { ActivitéService } from "../../../../services/ActivitéService";
import IconActivité from "../../../../assets/images/iconActivité.png";
import DEV from '../../../../url';

export default function ItemsActivities() {
  const service = new ActivitéService();

  const [activities, setActivities] = useState([]);

  const all = async () => {
    service.getAll().then((res) => {
      setActivities(res);
    });
  };

  useEffect(() => {
    all();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [pageNumberLimit] = useState(10);
  const [maxpageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minpageNumberLimit, setMinPageNumberLimit] = useState(0);

  const [searchitem, setSearchitem] = useState("");

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(activities.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = activities.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
      return (
        <li className="page-item" key={number} id={number}>
          <button onClick={handleClick} className="page-link">
            {number}
          </button>
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxpageNumberLimit) {
      setMaxPageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minpageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minpageNumberLimit - pageNumberLimit);
    }
  };

  return (
    <section className="tabs-content mt-3" id={"activities"}>
      <div className="row pt-5">
        <div className="col-md-4">
          {/*                     <input style={{ height: 50 }} type="text" name="globalFilter" onChange={(e) => setGlobalFilter(e.target.value)} className="form-control" placeholder="Cherchez une activité ..." autoComplete="on" />
           */}
          <select
            selected="0"
            className="form-control"
            style={{
              height: 50,
              borderTopRightRadius: 20,
              borderBottomLeftRadius: 20,
            }}
            name="searchitem"
            onChange={(e) => setSearchitem(e.target.value)}
          >
            <option disabled value="0">
              Sélectionner votre type d'activité
            </option>
            <option value="" onClick={all}>
              Tous les types d'activités
            </option>
            <option value="Intérieure">Intérieure</option>
            <option value="Extérieure">Extérieure</option>
            <option value="Culturelle">Culturelle</option>
            <option value="Atelier">Atelier</option>
          </select>
        </div>
      </div>

      <div className="row mt-3 last">
        {activities
          .filter((item_) => {
            if (searchitem === "") {
              return item_;
            } else if (
              item_.type.toLowerCase().includes(searchitem.toLowerCase())
            ) {
              return item_;
            }
          })
          .map((item, index) => (
            <div id={index} key={index} className="mt-3 col-lg-4">
              {/*<img src={`http://localhost:4000/uploads/`+item.img} alt={item.title} accept=".jpg , .png" />*/}
              {/*  <img src={`../uploads/activities/${item?.img}`} alt='' accept={".jpg"}/>*/}
              <div className="card-wrapper ">
                <div className="card-img">
                  <img
                    src={`${DEV}uploads/activities/${item?.img}`}
                    height={250}
                    width={500}
                    alt=""
                    accept={".jpg"}
                  />
                </div>
                <div className="card-body">
                  <div>
                    <img height={100} src={IconActivité} alt={item.title} />
                  </div>
                </div>
              </div>

              <div className="row w-100">
                <div className="col-12 mt-3" align={"center"}>
                  
                    <div  className="col-lg-6 col-sm-6">
                      <h6
                        style={{ fontSize: 17 }}
                        className="text-secondary"
                      >
                        {item.title}
                      </h6>
                    </div>
                    <div  className="col-lg-5 col-sm-6">
                      
                        {item.price == 0 ? (
                          <></>
                        ) : (
                          <p>
                            <b className={"text-success"}>
                              A partir de {item.price} {"DT / Personne"}
                            </b>
                          </p>
                        )}
                     
                    </div>
                
                  
                </div>
              </div>

              <br />
              <div align={"center"} className="">
                <a
                  href={`${item.id}|activity-details`}
                  className="filled-btn-avis"
                >
                  Voir Détails
                </a>
              </div>
              <div className="mt-4"></div>
            </div>
          ))}
      </div>

      <br />
      <br />
      <br />
    </section>
  );
}
