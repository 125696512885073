import React, { useState, useEffect } from "react";
import { ActivitéService } from "../../../../services/ActivitéService";
import Marquee from "react-fast-marquee";
import DEV from "../../../../url";

function ActivitiesTabs() {
  const [activities, setActivities] = useState([]);
  const service = new ActivitéService();

  useEffect(() => {
    service.getAll().then((res) => {
      setActivities(res);
    });
  }, []);
  return (
    <React.Fragment>
      <div className="container">
        <Marquee style={{ height: 400 }}>
          {activities.map((item, index) => (
            <a
              target={"_blank"}
              style={{ color: "#000", fontWeight: "bold" }}
              href={`${item.id}|activity-details`}
              className="mx-1 nav-item"
              key={index}
              rel="noreferrer"
            >
              <img
                alt="img-activité"
                src={`${DEV}uploads/activities/${item?.img}`}
                width={"auto"}
                height={250}
              />
              <div className="content">
                <h5 className="mt-3 text-center">{item.title}</h5>
                {
                    item.price === (0||"") ?
                    <></> : 
                    <h6 className="mt-3 text-center text-success">{item.price}{" DT / Pax"}</h6>
                }
              </div>
            </a>
          ))}
        </Marquee>
      </div>
      <div align="center">
        <a href="/activities" className="filled-btn-avis mt-2">
          Voir Plus
        </a>
      </div>
    </React.Fragment>
  );
}
export default ActivitiesTabs;
