import React, { useEffect, useState } from "react";
import { HeadingService } from "../../../../services/HeadingService";
import Scroll from "react-scroll-to-element";
import DEV from "../../../../url";

function OnParle() {
  const heading = new HeadingService();

  const [items, setItems] = useState([null]);

  const fetchHeading = () => {
    heading.allOnParNous().then((res) => {
      setItems(res.data);
    });
  };

  useEffect(() => {
    fetchHeading();
  }, []);

  return (
    <div>
      {items.map((row, index) => (
        <div
          key={index}
          style={{ backgroundImage: `url(${DEV}uploads/headings/${row?.img})` }}
          className="page-heading header-text"
        >
          {row?.type_heading === "On parle de nous" && (
            <div className="container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1
                      style={{ fontSize: "6vh", textTransform: "none" }}
                      className="text-center"
                    >
                      On parle de nous !{" "}
                    </h1>
                    <p
                      style={{
                        fontSize: 16.5,
                        color: "white",
                        wordSpacing: 5,
                        lineHeight: 1.5,
                      }}
                    >
                      Retrouvez tous les articles de presse, radios et chaînes
                      de télé dans lesquels nous sommes apparus{" "}
                    </p>
                  </div>
                </div>
              </div>
              <Scroll style={{ bottom: 0 }} type="id" element="onparledenous_">
                <button
                  style={{ marginTop: 0 }}
                  className={"filled-btn-scroll mt-5"}
                >
                  <i className={"fa fa-arrow-down"}></i>
                </button>
              </Scroll>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default OnParle;
