import React, { useEffect, useState } from "react";
import { ContactInformation } from "../../../../services/ContactInformation";
import { HeadingService } from "../../../../services/HeadingService";
import Scroll from "react-scroll-to-element";
import DEV from "../../../../url";
function RoomsHeading() {
  const contact = new ContactInformation();
  const [rows, setRows] = useState([]);

  const fetchall = async () => {
    contact.all().then((res) => {
      setRows(res);
    });
  };
  useEffect(() => {
    fetchall();
  }, []);

  const heading = new HeadingService();
  const [items, setItems] = useState([null]);

  const fetchHeading = () => {
    heading.allHeb().then((res) => {
      setItems(res.data);
    });
  };

  useEffect(() => {
    fetchHeading();
  }, []);
  return (
    <React.Fragment>
      {items.map((row, index) => (
        <div
          key={index}
          style={{ backgroundImage: `url(${DEV}uploads/headings/${row?.img})` }}
          className="page-heading header-text"
        >
          {row?.type_heading === "Hebergement" && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 style={{ fontSize: "6vh", textTransform: "none" }}>
                    Nos hébérgements
                  </h1>
                  <p
                    style={{
                      fontSize: 16.5,
                      color: "white",
                      wordSpacing: 8,
                      lineHeight: 1.5,
                    }}
                  >
                    Nous proposons toute une gamme de chambres pour subvenir
                    parfaitement à vos besoins : &nbsp;<b className="">Gîtes</b>
                    ,<b className=""> Chambres d'hôte</b>,{" "}
                    <b className="">Chalets</b> & <b>Suites</b>{" "}
                  </p>

                  <div align={"center"} className={"mt-3"}>
                    {rows.map((item_, index_) => (
                      <React.Fragment key={index_}>
                        {item_.title === "Hébérgement" ? (
                          <a
                            href={`tel:${item_.phonenumber}`}
                            className="filled-btn-reverse text-white"
                          >
                            {" "}
                            <i className="fa fa-phone mx-2" />{" "}
                            {item_.phonenumber}
                          </a>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                      <a
                    style={{ textTransform: "none" }}
                    target="_blank"
                    href="https://darzaghouan.kinko.info/iframe?key=H-sVS8XcnRsSKSYi5T5rbNRA4n"
                    className="filled-btn-avis mx-2 reserver-mobile-center"
                    rel="noreferrer"
                  >
                    Réserver votre chambre
                  </a>
                  </div>

                
                </div>
              </div>
              <Scroll style={{ bottom: 0 }} type="id" element="rooms">
                <button
                  style={{ marginTop: 0 }}
                  className={"filled-btn-scroll mt-5"}
                >
                  <i className={"fa fa-arrow-down"}></i>
                </button>
              </Scroll>
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
}

export default RoomsHeading;
