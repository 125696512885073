import {useRoutes} from "react-router-dom"
import AboutUs from "../components/body/pages/AboutUs/AboutUs";
import Activities from "../components/body/pages/activities/Activities";
import ActivityDetails from "../components/body/pages/activities/activityDetails/ActivityDetails";
import RoomDetails from "../components/body/pages/Rooms/roomDetails/RoomDetails";
import Rooms from "../components/body/pages/Rooms/Rooms";
import MainApp from "../components/MainApp";
import Restaurant from "../components/body/pages/Restaurant/Restaurant";
import ContactPage from "../components/body/pages/Contact/ContactPage";
import Terms from "../components/body/pages/Terms/Terms";
import Events from "../components/body/pages/Events/Events";
import Packs from "../components/body/pages/Packs/Packs";
import Products from "../components/body/pages/Products/Products";
import ProductDetails from "../components/body/pages/Products/ProductDetails/ProductDetails";
import DejeunerDetails from "../components/body/pages/Restaurant/Dejeuner/DejeunerDetails/DejeunerDetails";
import LaisserCommentaire from "../components/body/pages/LaisserCommentaire/LaisserCommentaire";
import PetitDejeunerDetails from "../components/body/pages/Restaurant/PetitDejeunerDetails/PetitDejeunerDetails";
import SpaIndex from "../components/body/pages/Spa/SpaIndex";
import VidéoChaine from "../components/body/pages/PassageVidéos/VidéoChaine";
import Reserver from "../components/body/pages/Reserver";
import PackDetails from "../components/body/pages/Packs/PackDetails";
import DetailsEvent from "../components/body/pages/Events/DetailsEvent";
import TisanerieDetails from "../components/body/pages/Restaurant/Tisanerie/TisanerieDetails";
import OnparledeNousIndex from "../components/body/pages/AboutUs/OnparledeNousIndex";
import SuccessBooking from "../components/body/pages/SuccessBooking";


const MainRoutes = ()=>{

    return useRoutes([
          {path: "/", element: <MainApp/>},
          {path: "onParledeNous", element: <OnparledeNousIndex/>},
          {path: "apropos", element: <AboutUs/>},
          {path: ":id|voirVideos", element: <VidéoChaine/>},
          {path: "activities", element: <Activities/>},
          {path: ":id|activity-details", element: <ActivityDetails/>},
          {path: "rooms", element: <Rooms/>},
          {path: ":id|room", element: <RoomDetails/>},
          {path: "restaurant", element: <Restaurant/>},

          {path: ":id|breakfast-details", element: <PetitDejeunerDetails/>},
          {path: ":id|lunch-details", element: <DejeunerDetails/>},
          {path: ":id|tisanerie-details", element: <TisanerieDetails/>},
          {path: "contact", element: <ContactPage/>},
          {path: "terms", element: <Terms/>},
          {path: "events", element: <Events/>},
          {path: ":id|evenement-details",element:<DetailsEvent/>},
          {path: "packs", element: <Packs/>},
          {path: ":id|pack-details", element: <PackDetails/>},
          {path: "products",element:<Products/>},
          {path: ":id|product-details",element:<ProductDetails/>},
          {path: "laisser-commentaire",element:<LaisserCommentaire/>},
          {path: "hammametspa",element:<SpaIndex/>},
          {path: "reserver",element:<Reserver/>},
          {path :"success",element:<SuccessBooking/>},
        
      ]);
}

export default MainRoutes ;
